<template>
  <div class="container flex flex-col items-center justify-center h-screen">
    <h1 class="font-bold text-3xl mb-4">Web Hatching System</h1>

    <div class="bg-white p-8 rounded-lg shadow text-center w-full">
      <input-field
        v-model="authorization"
        label="Authorization"
        class="w-64 mx-auto mb-4"
      />
      <button
        @click="getCartDetails"
        class="bg-blue-500 font-bold text-white px-4 py-2 rounded mb-6"
      >
        Get Details
      </button>

      <div v-if="cart" class="text-left">
        <pre>{{ cart }}</pre>
      </div>

      <select-field
        v-model="cartId"
        :reduce="option => option.id"
        label="Select cart"
        url="/carts"
        class="w-64 mx-auto mb-4"
      />

      <button
        @click="connect"
        class="bg-blue-500 font-bold text-white px-4 py-2 rounded"
      >
        Connect
      </button>
    </div>

    <div v-if="cartId">
      {{ channel }}
    </div>

    <div>
      <div v-for="(message, index) in messages" :key="index">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookie from 'js-cookie';

export default {
  data() {
    return {
      cartId: null,
      cart: null,
      authorization: null,
      messages: []
    };
  },
  computed: {
    channel() {
      return `cart.${this.cartId}`;
    }
  },
  methods: {
    setAuthorizationHeader() {
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${this.authorization}`;
    },
    getCsrfCookie() {
      return this.axios({ url: '/sanctum/csrf-cookie', baseURL: '/' });
    },
    setCsrfToken() {
      axios.defaults.headers.common['X-XSRF-TOKEN'] = Cookie.get('XSRF-TOKEN');
    },
    async getCartDetails() {
      await this.getCsrfCookie();
      this.setCsrfToken();
      this.setAuthorizationHeader();

      const { data } = await this.axios.get('/cart');
      this.cart = data.data;
    },
    connect() {
      this.setAuthorizationHeader();

      this.$echo.private(this.channel).listen('.cart.started', message => {
        this.messages.push(message);
      });
    }
  }
};
</script>
